<!--
 * @Description: 题目类别及其题目 【递归组件】
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-07 13:55:37
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-12 12:05:33
-->
<template>
  <div class="category-detail">
    <div class="row-title">{{categoryName}}</div>
    <div v-if="item.questions && item.questions.length > 0">
      <Question 
        v-for="(question, index) in item.questions"
        :item="question"
        :key="index"
      />
    </div>
    <div v-if="item.subs && item.subs.length > 0">
      <Category
        v-for="subItem in item.subs"
        :item="subItem"
        :key="subItem.id"
      />
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import Question from './Question.vue'
export default {
  name: 'Category',
  components: {
    Question
  },
  props: {
    item: Object
  },
  setup (props, ctx) {
    const categoryName = computed({
      get: () => {
        const level = props.item.level
        return props.item.info.padStart(level, '#')
      }
    })
    return {
      categoryName,
    }
  }
}
</script>

<style lang="less">
  .category-detail {
    margin-bottom: 24px;
    .row-title {
      padding-left: 4px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: normal;
      color: #2D2D33
    }
  }

</style>