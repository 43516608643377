/*
 * @Description: 问卷设置辅组函数
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-29 11:36:37
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-29 11:38:52
 */
function checkShowLogic(question, questionMap, split) {
  const showLogic = question.showLogic.split(split)
  let newShowLogic = []
  for (const item of showLogic) {
    const questionId = item.split(';')[0]
    // 关联题目在后则过滤
    if (questionMap[questionId] !== undefined) {
      newShowLogic.push(item)
    }
  }
  if (newShowLogic.length === 0) {
    question.showLogic = null
  } else {
    question.showLogic = newShowLogic.join(split)
  }
}

export function checkMove(questionnaire) {
  /**
   * 页面0不允许移动
   * 页面移动重构顺序
   * 章节移动需要重构顺序
   */
  let sort = 0
  let page = 0
  let part = null
  const partMapClone = JSON.parse(JSON.stringify(questionnaire.partMap))
  const length = questionnaire.questions.length
  // 检测页面0是否在头部
  const nowHeader = questionnaire.questions[0]
  if (nowHeader.questionType !== 'PAGE' || nowHeader.page !== 0) {
    for (let index = 0; index < length; index++) {
      const question = questionnaire.questions[index]
      if (question.questionType === 'PAGE') {
        // 如果页数0不在头部，则把它取出来再放进头部
        if (question.page === 0 && index !== 0) {
          const header = questionnaire.questions.splice(index, 1)
          questionnaire.questions.unshift(header[0])
          break
        }
      }
    }
  }
  
  const questionMap = {}
  for (let index = 0; index < length; index++) {
    const question = questionnaire.questions[index]
    if (question.hasOwnProperty('sort')) {
      question.sort = sort++
      question.page = page
      question.part = part
      questionMap[question.questionId] = question.sort
      // 检查跳转逻辑是否合法
      if (question.jumpLogic) {
        const jumpLogic = JSON.parse(question.jumpLogic)
        for (const id in jumpLogic) {
          const questionId = jumpLogic[id]
          // 关联题目在前则过滤
          if (questionMap[questionId] !== undefined) {
            delete jumpLogic[id]
          }
        }
        if (Object.keys(jumpLogic).length > 0) {
          question.jumpLogic = JSON.stringify(jumpLogic)
        } else {
          question.jumpLogic = null
        }
      }
      // 检查题目显示逻辑是否合法
      if (question.showLogic) {
        const isOr = question.showLogic.indexOf('|') > -1
        const isAnd = question.showLogic.indexOf('&') > -1
        if (isOr) {
          checkShowLogic(question, questionMap, '|')
        } else if (isAnd) {
          checkShowLogic(question, questionMap, '&')
        } else {
          checkShowLogic(question, questionMap, ' ')
        }
      }
      
      if (question.choices) {
        for (const choice of question.choices) {
          // 检查选项显示逻辑是否合法
          if (choice.showLogic) {
            const isOr = choice.showLogic.indexOf('|') > -1
            const isAnd = choice.showLogic.indexOf('&') > -1
            if (isOr) {
              checkShowLogic(choice, questionMap, '|')
            } else if (isAnd) {
              checkShowLogic(choice, questionMap, '&')
            } else {
              checkShowLogic(choice, questionMap, ' ')
            }
          }
        }
      }

    } else if (question.questionType === 'PAGE') {
      // 重构页数顺序
      if (question.page !== 0) {
        page++
        question.page = page
        questionnaire.pageMap[page] = `第${page + 1}页`
      }
    } else if (question.questionType === 'PART') {
      // 重构PART
      if (part == null) {
        part = -1
      }
      part++
      if (part !== question.part) {
        const oldPart = question.part
        question.part = part
        questionnaire.partMap[part] = partMapClone[oldPart]
      }
      questionnaire.partMap[part].page = page
      question.page = page
    }
  }
}
