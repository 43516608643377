<!--
 * @Description: 题库
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-20 16:35:45
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-12 17:18:07
-->

<template>
  <div class="question-library-modal">
    <div class="modal-mask"></div>
    <div class="modal-wrap">
      <div class="modal">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-title">题库类型——{{libraryInfo.info}}</div>
            <span 
              class="iconfont close"
              @click="onClose"
            >
              &#xe61c;
            </span>
          </div>
          <div class="modal-body">
            <div class="modal-body-row">
              <Question 
                v-for="(question, index) in libraryInfo.questions"
                :item="question"
                :key="index"
              />
            </div>
            <Category
              v-for="item in libraryInfo.subs"
              :item="item"
              :key="item.id"
            />
          </div>
          <div class="modal-footer">
            <div class="bottom-cancel" @click="onClose">取消</div>
            <div class="bottom-finish" @click="onConfirm">添加{{checkedQuestionNum}}个题目到当前问卷中</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Category from './components/Category.vue'
import Question from './components/Question.vue'

import globalQuestionnaire from '@/class/useQuestionnaire'
import createQuestionPrototypeEffect from '@/utils/question.js'

import { ref, watch, onUnmounted } from 'vue'
import { getAllQuestionsWithCategory } from '@/api/library'
import { deepCopy } from '@/utils/deepCopy'

const FIRST_LEVEL = 0

/**
 * 题库信息
 */
const libraryEffect = (props) => {
  const libraryInfo = ref({})    // 一级分类下的题库信息
  // 为层级树设置层级信息
  const setLevelInfo = (list, level) => {
    const curLevel = level
    list.forEach(item => {
      item.level = curLevel
      if (item.subs && item.subs.length > 0) {
        item.isLeaf = false
        const nextLevel = curLevel + 1
        setLevelInfo(item.subs, nextLevel)
      } else {
        item.isLeaf = true
      }
    })
    return list
  }
  // 获取题库所有信息
  const getAllLibraryInfo = async () => {
    const result = await getAllQuestionsWithCategory()
    if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
      const allLibraryInfo = setLevelInfo(result.data.data, FIRST_LEVEL) // 题库所有信息
      libraryInfo.value = allLibraryInfo.filter(item => item.id === props.categoryId)[0]
   } else {
      message.error(' 请求题库失败，失败原因：' + result.data.msg)
    }
  }
  getAllLibraryInfo()
  return {
    libraryInfo,
  }
}

/**
 * 模态窗按钮相关操作
 */
const buttonEffect = (ctx) => {
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const questionLibrary = globalQuestionnaire.getQuestionLibrary()
  const createQuestionPrototype = createQuestionPrototypeEffect()
  const checkedQuestionNum = ref(0)
  watch(
    () => questionLibrary.value.length,
    val => {
      checkedQuestionNum.value = val
    }
  )
  // 批量添加题目
  const batchAddQuestions = (questList) => {
    const curEditQuests = questionnaire.questions.filter(question => !/^(PAGE|PART)$/.test(question.questionType))
    const pageIndexList = Object.keys(questionnaire.pageMap)
    const partIndexList = Object.keys(questionnaire.partMap)
    let lastPageIndex = pageIndexList.length === 0 ? null : Number(pageIndexList[pageIndexList.length - 1])
    let lastPartIndex = partIndexList.length === 0 ? null: Number(partIndexList[partIndexList.length - 1])
    let lastSortIndex = curEditQuests.length === 0 ? null : Number(curEditQuests.length - 1)
    
    if (lastPageIndex === null) {
      globalQuestionnaire.addQuestion(createQuestionPrototype("Page", 0, null))
      globalQuestionnaire.addPage(0)
    }
    questList.forEach((question, index) =>{
      const curPageIndex = lastPageIndex === null ? 0 : lastPageIndex
      const curPartIndex = lastPartIndex
      const curSortIndex = lastSortIndex === null ? index : lastSortIndex + index + 1
      question.page = curPageIndex
      question.part = curPartIndex
      question.sort = curSortIndex
      globalQuestionnaire.addQuestion(question)
    })
    setTimeout(() => {
      let lastChildren
      const parent = document.getElementsByClassName('question-group')[0]
      parent.children.forEach((children) => {
        if (children.className) {
          lastChildren = children
        }
      })
      if (lastChildren) {
        lastChildren.scrollIntoView({ block: 'end', behavior: 'smooth' })
      }
    }, 0)
  }
  const onConfirm = () => {
    if (checkedQuestionNum.value === 0) return
    batchAddQuestions(questionLibrary.value)
    ctx.emit('modal-on-confirm')
  }
  const onClose = () => {
    ctx.emit('modal-on-close')
  }
  return {
    checkedQuestionNum,
    onConfirm,
    onClose,
  }
}


export default {
  name: 'QuestionLibrary',
  components: {
    Category,
    Question,
  },
  props: {
    categoryId: String, // 题库一级类别id
  },
  emits: [
    'modal-on-confirm',
    'modal-on-close',
  ],
  setup (props, ctx) {
    const { libraryInfo } = libraryEffect(props)
    const { checkedQuestionNum, onConfirm, onClose } = buttonEffect(ctx)
    const questionLibrary = globalQuestionnaire.getQuestionLibrary()
    onUnmounted(() => {
      questionLibrary.value = [] // 清空全局已选列表
    })
    return {
      libraryInfo,
      checkedQuestionNum,
      onConfirm,
      onClose,
      questionLibrary,
    }
  }
}
</script>

<style lang="less">
.question-library-modal {
  .modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    .modal {
      box-sizing: border-box;
      width: 900px;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      left: 50%; 
      top: 50%;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
      -webkit-transform: translate(-50%, -50%);
      pointer-events: none;
      .modal-content {
        position: relative;
        border-radius: 6px;
        background-color: #fff;
        padding: 30px 20px;
        pointer-events: auto;
        .modal-header {
          position: relative;
          margin: 16px 24px;
          padding-bottom: 28px;
          color: rgba(0, 0, 0, 0.85);
          background: #fff;
          border-bottom: 1px solid #F1F1F1;
          box-sizing: border-box;
          .modal-header-title {
            width: auto;
            font-size: 20px;
            color: #323336;
            font-weight: 500;
            word-wrap: break-word;
            display: inline-block;
          }
          .close {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 20px;
            color: #1BABEC;
            .pointer()
          }
        }
        .modal-body {
          padding: 20px 24px 40px 24px;
          min-height: 300px;
          max-height: 600px;
          overflow: auto;
          .modal-body-row {
            margin-bottom: 24px;
            .row-title {
              padding-left: 4px;
              margin-bottom: 20px;
              font-size: 16px;
              font-weight: normal;
              color: #2D2D33
            }
          }
        }
        .modal-footer {
          display: flex;
          margin: 34px 0;
          justify-content: center;
          div {
            flex: none;
            align-self: center;
            padding: 10px 20px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            color: #FFFFFF;
            background-color: #00A0E9;
            cursor: pointer;
          }
          .bottom-cancel, .bottom-pre {
            color: #c8c8c8;
            background-color: #fff;
            border: 1px solid #c8c8c8;
            margin-right: 40px;
          }
          .bottom-finish {
            border: 1px solid #00A0E9;
            padding: 10px 20px;
          }
        }
      }
    }
  } 
}
</style>>