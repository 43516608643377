/*
 * @Description: 问卷编辑操作
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-15 17:27:37
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-16 11:21:58
 */

import globalQuestionnaire from '@/class/useQuestionnaire'
import useCommonConstructEffect from '@/effects/constructEffect'
import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'
import createQuestionPrototypeEffect from '@/utils/question.js'

import { getQuestionnaireById } from '@/api/questionnaire'
import { toRefs, reactive, onUnmounted, ref, watch, onBeforeUnmount, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { message } from 'ant-design-vue'
import { getTerminalType } from '@/utils/userAgent.js'
import { saveQuestionnaire, saveEditQuestionnaire, releaseQuestionnaire } from '@/api/questionnaire'
import { checkMove } from './utils'

/**
 * 异步获取问卷信息
 */
const getQuestionnaireByAsync = async (id) => {
  const { constructQuestion } = useCommonConstructEffect()
  const result = await getQuestionnaireById(id)
  if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
    globalQuestionnaire.setQuestionnaire(constructQuestion(result.data.data))
  } else {
    message.error(' 请求答卷失败，失败原因：' + result.data.msg)
  }
}

/**
 * 同步新建问卷信息
 */
const getQuestionnaireByNew = () => {
  const newQuestionnaire = {
    // "questionnaireProtoId" : createUniqueId(10), // 问卷id由后端负责
    "title" : "",
    "ifHaveQuestionnaire" : true,
    "description" : "",
    "questions" : [],
    "pageMap" : {
      // 0 : "第1页",
    },
    "partMap" : {}
  }
  globalQuestionnaire.setQuestionnaire(newQuestionnaire)
}

/**
 * 获取问卷信息
 */
const getQuestionnaireEffect = () => {
  const route = useRoute()
  const id = route.params.id
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  if (id === "new") {
    getQuestionnaireByNew()
  } else {
    getQuestionnaireByAsync(id)
  }
  return {
    questionnaire
  }
}

/**
 * 头部功能按钮点击事件
 */
const handleTopBtnEffect = () => {
  const route = useRoute()
  const router = useRouter()
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  console.log(questionnaire)
  const { filterQuestionaireEffect } = useCommonQuestionnaireEffect()
  // 校验
  const checkData = (questionnaire) => {
    const warnTips = []
    // 校验标题
    if (!questionnaire.title) {
      warnTips.push('问卷名不能为空')
    }
    // 校验章节
    Object.values(questionnaire.partMap).forEach((partData, index) => {
      if (!partData.title) {
        warnTips.push(`第${index + 1}章节标题不能为空`)
      }
    })
    return warnTips.join('\n')
  }

  // 自动补全默认问卷标题和章节名称
  const autoCompletionText = (questionnaire) => {
    if (!questionnaire.title) {
      questionnaire.title = '问卷标题'
    }
    Object.values(questionnaire.partMap).forEach((partData, index) => {
      if (!partData.title) {
        partData.title = '章节标题'
      }
    })
    
    questionnaire.questions.forEach(question => {
      question.title ? null : question.title = "标题"
      let questionType = question.questionType
      // 单选、多选、下拉题默认文字补全
      if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE|DROP_DOWN)$/.test(questionType)) {
        question.choices.forEach(choice => choice.text ? null : choice.text = "选项")
      // 章节默认文字补全
      } else if (/^PART$/.test(questionType)) {
        !question.title ? (question.title = '章节标题') : null
      }
    })
  }
  
  // 保存
  const save = async () => {
    autoCompletionText(questionnaire)
    let newQuestionnaire = filterQuestionaireEffect(questionnaire)
    let res = null
    // 新增保存
    if (route.params.id == 'new') {
      res = await saveQuestionnaire(newQuestionnaire)
      if (res.data?.code === '00000') {
        router.push(`/edit/questionEdit/${res.data.data}`) // 创建成功后跳到编辑
      }
    } else {
    // 编辑保存
      const questionnaireProtoId = route.params.id
      delete newQuestionnaire.questionnaireProtoId
      res = await saveEditQuestionnaire(newQuestionnaire, questionnaireProtoId)
      newQuestionnaire.questionnaireProtoId = questionnaireProtoId // 恢复问卷questionnaireProtoId
      isChange.value = false
    }
    if (res.status === 200) {
      if (res.data?.code === '00000') {
        message.success('问卷内容保存成功', 1)
      } else {
        message.error('保存失败，失败原因：' + res.data.msg)
      }
    } else {
      message.error('保存失败，失败原因：' + res.msg)
    }
  }
  // 发布
  const publish = async () => {
    isChange.value = false
    autoCompletionText(questionnaire)
    const newQuestionnaire = filterQuestionaireEffect(questionnaire)
    let id = ''
    let res = null
    // 先保存再发布
    if (route.params.id == 'new') {
      res = await saveQuestionnaire(newQuestionnaire)
      if (res.data?.code === '00000') {
        id = res.data.data
      }
    } else {
      id = route.params.id
      delete newQuestionnaire.questionnaireProtoId
      res = await saveEditQuestionnaire(newQuestionnaire, id)
    }
    if (res.data?.code != '00000') {
      message.error('发布失败')
      return
    }
    let resTwo = await releaseQuestionnaire(id)
    if (resTwo.data?.code === '00000') {
      message.success(`发布成功`)
      router.push(`/detail/${id}/dispatchQuestion`)
    } else {
      message.error(`发布失败，失败原因：${res.msg}`)
    }
  }
  // 预览
  const preview = async () => {
    autoCompletionText(questionnaire)
    const newQuestionnaire = filterQuestionaireEffect(questionnaire)
    let id = ''
    let res = null
    if (route.params.id == 'new') {
      const newQuestionnaire = filterQuestionaireEffect(questionnaire)
      const res = await saveQuestionnaire(newQuestionnaire)
      if (res.data?.code === '00000') {
        id = res.data.data
      }
    } else {
      id = route.params.id
      delete newQuestionnaire.questionnaireProtoId
      res = await saveEditQuestionnaire(newQuestionnaire, id)
    }
    if (res.data?.code != '00000') {
      message.error('预览失败')
      return
    }
    const obj = router.resolve({
      path: `/preview/${id}`,
    });
    window.open(obj.href, "_blank");
  }
  return {
    save,
    publish,
    preview,
  }
}

/**
 * 模块导航
 */
const moduleSideBarEffect = (qTypeBarVisibile) => {
  const route = useRoute()
  const curClickModule = ref('edit')
  if (route.query.module && route.query.module === 'library') {
    curClickModule.value = 'library'
  }
  const handleModuleSideBarClick = (moduleName) => {
    curClickModule.value = moduleName // 当前点击模块名称（题型：edit，题库：library）
    if (route.query.isKeepAnswerAndEdit) {
      return
    }
    qTypeBarVisibile.value = false
    nextTick(() => {
      qTypeBarVisibile.value = true
    })
  }
  return {
    curClickModule,
    qTypeBarVisibile,
    handleModuleSideBarClick,
  }
}

/**
 * 题型、题库导航入口控制
 */
const typeOrLibraryBarControlEffect = (handleAddComponent, handleOpenQuestionModal) => {
  const handleQuestionSideBarClick = (id, module) => {
    if (module === 'edit') {
      handleAddComponent(id)
    } else {
      handleOpenQuestionModal(id)
    }
  }
  return {
    handleQuestionSideBarClick
  }
}

/**
 * 题型导航
 */
const qTypeSideBarEffect = () => {
  /**
 * 新增题目
 * if 新建题目
 *       首先获取vuex的questionnire，然后重底部向上遍历question，判断最底部的 questionType="PAGE" | "PART" 的数据，获取分页、章节数据
 *       传给createQuestionPrototype(componentName, page, part)
 *  if 新建分页
 *       首先获取vuex的questionnire，判断最后一个question.questionTyp === "PAGE"  
 *           if  question.questionTyp === "PAGE" 则提示用户 “上一分页数据为空，取消操作”
 *           else 新增分页组件
 *  if 新增章节
 *       首先获取vuex的questionnire，判断最后一个question.questionTyp === "PART" 
 *           if  question.questionTyp === "PART" 则提示用户 “上一章节数据为空，取消操作”
 *           else 新增章节组件
 */
  const createQuestionPrototype = createQuestionPrototypeEffect()
  // 获取新增题目的页码、章节、序号
  const getNewQuestConfig = (componentName) => {
    const config = {
      pageIndex: null,
      partIndex: null,
      sort: null
    }
    const questionnaire = globalQuestionnaire.getQuestionnaire()
    // 1、获取序号
    const endQuestion = questionnaire.questions.filter(question => !/^(PART|PAGE)$/.test(question.questionType)).pop()
    config.sort = endQuestion ? (endQuestion.sort + 1) : 0
    if (/^(Part|Page)$/.test(componentName)) { (config.sort = null) }
    // 2、获取页码
    const endPageIndex = Math.max(...Object.keys(questionnaire.pageMap))
    config.pageIndex = endPageIndex === -Infinity ? -999 : endPageIndex
    if (/^Page$/.test(componentName)) { ++config.pageIndex }
    // 3、获取章节
    if (Object.keys(questionnaire.partMap).length > 0) {
      config.partIndex = Math.max(...Object.keys(questionnaire.partMap))
      if (/^Part$/.test(componentName)) {
        ++config.partIndex
      }
    }
    return config
  }
  // 处理组件新增
  const handleAddComponent = (componentName, moudle) => {
    store.commit('changeCurEditQuestionId', '')
    const questionnaire = globalQuestionnaire.getQuestionnaire()
    let { pageIndex, partIndex, sort }  = getNewQuestConfig(componentName)
    // 默认增加第1页
    if (pageIndex < 0) {
      globalQuestionnaire.addQuestion(createQuestionPrototype("Page", 0, null))
      globalQuestionnaire.addPage(0)
      pageIndex = 0
    }
    // 新增题型
    if (!/^(Page|Part)$/.test(componentName)) {
      globalQuestionnaire.addQuestion(createQuestionPrototype(componentName, pageIndex, partIndex, sort))
    // 新增分页
    } else if (/^Page$/.test(componentName)) {
      if (pageIndex === 0) return
      globalQuestionnaire.addQuestion(createQuestionPrototype(componentName, pageIndex, null))
      globalQuestionnaire.addPage(pageIndex)
    // 新增章节
    } else {
      let newPartIndex = (partIndex === null ? 0 : Number(partIndex))
      globalQuestionnaire.addPart(newPartIndex, pageIndex)
      globalQuestionnaire.addQuestion(createQuestionPrototype(componentName, pageIndex, newPartIndex, "", questionnaire.partMap))
    }
    setTimeout(() => {
      let lastChildren
      const parent = questionList.value.targetDomElement
      parent.children.forEach((children) => {
        if (children.className) {
          lastChildren = children
        }
      })
      if (lastChildren) {
        lastChildren.scrollIntoView({ block: 'end', behavior: 'smooth' })
      }
    }, 300)
  }
  return {
    handleAddComponent
  }
}

/**
 * 题库导航
 */
const qLibrarySideBarEffect = () => {
  const qLibraryModalVisible = ref(false)
  const categoryId = ref('') // 题库一级类别id
  // 题库点击确定后回调
  const handleQlibraryCallback = () => {
    qLibraryModalVisible.value = false
  }
  // 打开题库模态窗
  const handleOpenQuestionModal = (id) => {
    qLibraryModalVisible.value = true
    categoryId.value = id
  }
  return {
    qLibraryModalVisible,
    categoryId,
    handleOpenQuestionModal,
    handleQlibraryCallback,
  }
}

// 是否修改
let isChange = ref(false)
let store
const questionList = ref(null)

/**
 * 程序入口
 */
function questionEditEffect() {
  const isPc = getTerminalType() === 'PC' ? true : false
  store = useStore()
  const router = useRouter()
  const route = useRoute()
  const qTypeBarVisibile = ref(!route.query.isKeepAnswerAndEdit) // 是否显示题型/题库侧边导航栏
  const { questionnaire } = getQuestionnaireEffect()
  const { curClickModule, handleModuleSideBarClick } = moduleSideBarEffect(qTypeBarVisibile)
  const { handleAddComponent } =qTypeSideBarEffect() 
  const { qLibraryModalVisible, categoryId, handleOpenQuestionModal, handleQlibraryCallback } = qLibrarySideBarEffect()
  const { save, publish, preview } = handleTopBtnEffect()
  const { handleQuestionSideBarClick }  = typeOrLibraryBarControlEffect(handleAddComponent, handleOpenQuestionModal)
    
  // 处理页面的点击事件，判断点击的是不是题目
  const handleEditWrapperClick = (e) => {
    // console.log('eeee', e) // TODO: 看结构，dom结构不能随便该
    for (let i = 0; i < e.path.length; i++) {
      if (e.path[i].className == 'content') {
        if (i == 0) {
          store.commit('changeCurEditQuestionId', '')
        }
        return
      }
    }
    store.commit('changeCurEditQuestionId', '')
  }
    
  // 关闭提醒
  const linster = (e) => {
    const confirmationMessage = () => {}
    (e || window.event).returnValue = confirmationMessage
    return confirmationMessage
  }

  let firstUpdate = false

  watch(() => questionnaire.questions, (newValue, oldValue) => {
    if (firstUpdate) {
      isChange.value = true
    }
    firstUpdate = true
  }, {
    deep: true
  })

  watch(isChange, (newValue, oldValue) => {
    if (newValue) {
      window.addEventListener('beforeunload', linster, false)
    } else {
      window.removeEventListener('beforeunload', linster, false)
    }
  })

  onBeforeRouteLeave((to, from) => {
    if (to.fullPath.indexOf('detail') === -1 && to.fullPath.indexOf('questionSetting') === -1 && isChange.value) {
      const result = confirm('你所做的更改可能未保存。是否退出')
      if (result) {
        return true
      }
      return false
    }
    return true
  })

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', linster, false)
  })
  
  return {
    isPc,
    route,
    questionnaire,
    qTypeBarVisibile,
    curClickModule,
    handleModuleSideBarClick,
    handleQuestionSideBarClick,
    save,
    publish,
    preview,
    handleEditWrapperClick,
    questionList,
    qLibraryModalVisible,
    categoryId,
    handleQlibraryCallback,
    checkMove
  }
}

export default questionEditEffect