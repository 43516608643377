<!--
 * @Description: 问卷编辑
 * @version: 
 * @Author: PSG
 * @Date: 2021-05-17 15:27:28
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-28 10:50:45
-->
<template>
<div class="question-edit" @mousedown="handleEditWrapperClick">
  <Header
    :isShowPersonal="true"
    :isShowFunction="true"
    :isShowHelp="true"
    @save="save"
    @publish="publish"
  />
  <div class="center">
    <div class="content">
      <div class="left-content">
        <ModuleSideBar 
          :isShowEdit="true"
          :isShowLibrary="true"
          :isShowSetting="true"
          @save="save"
          @getSideBarClickInfo="handleModuleSideBarClick"
        />
        <QuestionTypeBar
          class="question-type-bar"
          v-if="qTypeBarVisibile" 
          :moudle="curClickModule"
          @getSideBarClickInfo="handleQuestionSideBarClick"
        />
      </div>
      <div class="right-content">
        <div v-if="questionnaire.questions && questionnaire.questions.length > 0">
          <component
            style="margin-top: 0px"
            :is="questionnaire.questions[0].componentName"
            :item="questionnaire.questions[0]"
            :index="0"
            :isPc="isPc"
          />
        </div>
        <Title 
          :title="questionnaire.title" 
          :description="questionnaire.description"
          :isPc="isPc"
          placeholder="问卷标题"
          descPlaceholder="问卷说明（选填）"
        />
        <draggable
          :list="questionnaire.questions"
          item-key="questionId"
          handle=".preview-sort"
          class="question-group"
          ref="questionList"
          @end="checkMove(questionnaire)"
        >
          <template #item="{ element, index }">
            <div class="question-group-item" :id="element.questionId" v-if="index !== 0">
              <component
                :is="element.componentName"
                :item="element"
                :index="index"
                :isPc="isPc"
              />
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</div>
<QuestionLibrary
  v-if="qLibraryModalVisible"
  :categoryId="categoryId"
  @modal-on-close="qLibraryModalVisible=false"
  @modal-on-confirm="handleQlibraryCallback"
/>
</template>

<script>
import Header from '@/components/common/QuestionHeader'
import ModuleSideBar from '@/components/common/QuestionSideBar'
import QuestionTypeBar from './components/QuestionTypeBar'

import QuestionLibrary from './modal/QuestionLibray/QuestionLibrary.vue'

import Title from '@/components/question/others/Title'
import Page from '@/components/question/others/Page'
import Part from '@/components/question/others/Part'
import SingleChoice from '@/components/question/SingleChoice'
import MultipleChoice from '@/components/question/MultipleChoice'
import DropDown from '@/components/question/DropDown'
import Scale from '@/components/question/Scale'
import MatrixScale from '@/components/question/MatrixScale'
import MatrixSingleChoice from '@/components/question/MatrixSingleChoice'
import MatrixMultipleChoice from '@/components/question/MatrixMultipleChoice'
import Short from '@/components/question/Short'
import Date from '@/components/question/Date'

import draggable from 'vuedraggable'
import questionEditEffect from './questionEditEffect'
import checkMoveEffect from './checkMoveEffect'

export default {
  components: { 
    Header, 
    ModuleSideBar,
    QuestionTypeBar,
    QuestionLibrary,

    Title,
    Page,
    Part,
    SingleChoice,
    MultipleChoice,
    DropDown,
    Scale,
    MatrixScale,
    MatrixSingleChoice,
    MatrixMultipleChoice,
    Short,
    Date,
    draggable
  },
  /**
   * 程序入口
   */
  setup () {
    const {
      isPc,route,
      questionnaire,
      qTypeBarVisibile,
      curClickModule,
      handleModuleSideBarClick,
      handleQuestionSideBarClick,
      save,
      publish,
      preview,
      handleEditWrapperClick,
      questionList,
      qLibraryModalVisible,
      categoryId,
      handleQlibraryCallback } = questionEditEffect()

      const { checkMove } = checkMoveEffect()

    return {
      isPc,
      route,
      questionnaire,
      qTypeBarVisibile,
      curClickModule,
      handleModuleSideBarClick,
      handleQuestionSideBarClick,
      save,
      publish,
      preview,
      handleEditWrapperClick,
      questionList,
      qLibraryModalVisible,
      categoryId,
      handleQlibraryCallback,
      checkMove
    }
  }
}
</script>

<style lang="less" scoped>
.question-edit {
  width: 100vw;
  height: 100vh;
  background-color: @backgroundColor;
}
.center {
  position: fixed;
  top: 122px;
  width: 100%;
  height: calc(100% - 100px);
  overflow: hidden;
  .content {
    display: flex;
    width: 1300px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    .left-content {
      display: flex;
      height: 100%;
      .question-type-bar {
        margin-right: 5px;
        margin-left: 5px;
        height: 100%;
      }
    }
    .right-content {
      max-width: 1046px;
      height: 100%;
      overflow-y: scroll;
    }
  }
  
}
</style>