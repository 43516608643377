<!--
 * @Description: 题型、题库导航栏
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-24 18:31:01
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-15 19:04:55
-->
<template>

  <div class="question-type-bar">
    <!-- 题型导航 -->
    <template v-if="moudle === 'edit'">
      <h1>题型</h1>
      <ul>
        <li 
          v-for="item in types" 
          :key="item.questionType"
          @click="handleClick(item.component)"
        >
          {{item.questionName}}
        </li>
      </ul>
      <h1>其他</h1>
      <ul>
        <li
          v-for="item in others"
          :key="item.otherType"
          @click="handleClick(item.component)"
        >
          {{item.otherName}}
        </li>
      </ul>
    </template>
    <!-- 题库导航 -->
    <template v-else>
      <h1>题型</h1>
      <ul style="border-bottom: none">
        <li 
          v-for="item in types" 
          :key="item.id"
          @click="handleClick(item.id)"
        >
          {{item.info}}
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { getQLibraryType } from '@/api/library'
import { questionTypeList, othersList } from '@/utils/enum.js'
import { ref } from 'vue'
import { createUniqueId } from '@/utils/random'

/**
 * 导航处理
 */
const questionnaireTypeEffect = (props, ctx) => {
  const types = ref([])
  const others = ref([])
  // 获取题型类型
  const getQuestionType = () => {
    types.value = questionTypeList
    others.value = othersList
  }
  // 获取题库类型
  const getQlibraryType = async () => {
    const result = await getQLibraryType()
    if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
      types.value = result.data.data
    } else {
      message.error(' 请求题库类型失败，失败原因：' + result.data.msg)
    }
  }
  // 点击事件
  const handleClick = (id) => {
    ctx.emit('getSideBarClickInfo', id, props.moudle)
  }
  props.moudle === 'edit' ? getQuestionType() : getQlibraryType()
  return {
    types,
    others,
    handleClick
  }
}

export default {
  name: 'QuestionTypeBar',
  props: ['moudle'],
  setup (props, ctx) {
    const { types, others, handleClick } = questionnaireTypeEffect(props, ctx)
    return {
      types,
      others,
      handleClick
    }
  }
}
</script>

<style scoped lang="less">
.question-type-bar {
  width: 121px;
  box-shadow: 0px 0px 4px 0px #dfdfdf;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  h1 {
    margin: 17px 0 6px 0;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  ul {
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 10px;
    li {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      margin: 8px 12px;
      text-align: center;
      height: 36px;
      line-height: 36px;
      font-weight: 100;
      cursor:pointer;
      &:hover {
        background-color: @hoverBackground;
      }
    }
    &:nth-child(4) {
      border-bottom: none;
    }
  }
}
</style>