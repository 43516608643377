/*
 * @Description: Draggable组件-拖动排序之后的回调处理事件
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-26 11:40:09
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-26 15:49:52
 */
import { createUniqueId } from '@/utils/random'
import useCommonConstructEffect from '@/effects/constructEffect'

// 单选、多选默认选项
const DEFAULT_CHOICES = [{
    "ifAllowFillInBlanks" : false,
    "pic" : "",
    "ifRequired" : false,
    "choiceId" : createUniqueId(8),
    "description" : "",
    "ifRepel": false,
    "text" : "选项",
  }, {
    "ifAllowFillInBlanks" : false,
    "pic" : "",
    "ifRequired" : false,
    "choiceId" : createUniqueId(8),
    "description" : "",
    "ifRepel": true,
    "text" : "选项",
  }
]

// 矩阵单选、矩阵量表默认行标题选项
const DEFAULT_ROWITEMS_SING = [{
    "text": "腾讯",
    "description": ""
  }, {
    "text": "阿里巴巴",
    "description": ""
  }, {
    "text": "百度",
    "description": ""
  }
]

// 矩阵多选默认行标题选项
const DEFAULT_ROWITEMS_MULT = [{
    "text": "芒果TV",
    "description": ""
  }, {
    "text": "腾讯视频",
    "description": ""
  }, {
    "text": "爱奇艺",
    "description": ""
  }
]

const checkMoveEffect = () => {

  const { insertLogicTips } = useCommonConstructEffect()

  /**
   * 获取系统默认选项/行标题
   * @param {string} questionType 
   * @returns list 系统默认选项/行标题
   */
  const getDefaultChoice = (questionType) => {
    let list = []
    if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/g.test(questionType)) {
      list = JSON.parse(JSON.stringify(DEFAULT_CHOICES))
    } else if (/^(MATRIX_SCALE|MATRIX_SINGLE_CHOICE)$/g.test(questionType)) {
      list = JSON.parse(JSON.stringify(DEFAULT_ROWITEMS_SING))
    } else if (/^(MATRIX_MULTIPLE_CHOICE)$/g.test(questionType)) {
      list = JSON.parse(JSON.stringify(DEFAULT_ROWITEMS_MULT))
    }
    return list
  }

  /**
   * 检查条件跳题
   * @param {object} question 
   * @param {object} questionMap 
   */
  const condJumpLogicCheck = (question, questionMap) => {
    const jumpLogic = JSON.parse(question.jumpLogic)
    for (const id in jumpLogic) {
      const targetQuestionId = jumpLogic[id]
      // 关联题目在前则过滤
      if (questionMap[targetQuestionId] !== undefined) {
        delete jumpLogic[id]
      }
    }
    question.jumpLogic = Object.keys(jumpLogic).length > 0 
      ? JSON.stringify(jumpLogic)
      : null
  }

  /**
   * 检查无条件跳题
   * @param {object} question 
   * @param {object} questionMap 
   */
  const noCondJumpLogicCheck = (question, questionMap) => {
    const targetQuestionId = JSON.parse(question.jumpLogic)
    // 关联题目在前则过滤
    if (questionMap[targetQuestionId] !== undefined) {
      question.jumpLogic = null
    }
  }

  /**
   * 检查题目、选项显示
   * @param {object} question 
   * @param {object} questionMap 
   * @param {string} split 
   */
  const showLogicCheck = (item, questionMap, split) => {
    const showLogic = item.showLogic.split(split)
    let newShowLogic = []
    for (const item of showLogic) {
      const questionId = item.split(';')[0]
      // 关联题目在后则过滤
      if (questionMap[questionId] !== undefined) {
        newShowLogic.push(item)
      }
    }
    item.showLogic = newShowLogic.length === 0 
      ? null 
      : newShowLogic.join(split)
  }

  /**
   * 检查选项引用
   * @param {object} question 
   * @param {object} questionMap
   */
  const refLogicCheck = (question, questionMap) => {
    const questionType = question.questionType
    const targetQuestionId = question.ref
    if (questionMap[targetQuestionId] === undefined) {
      // 引用逻辑清空
      question.ref = null
      // 引用选项默认值复原
      const key = /^MATRIX_/g.test(questionType) ? 'rowItems' : 'choices'
      question[key] = getDefaultChoice(questionType)
    }
  }

  /**
   * Draggable组件-拖动排序之后的回调处理事件
   *   规则：
   *      页面0不允许移动
   *      页面移动重构顺序
   *      章节移动需要重构顺序
   */
  const checkMove = (questionnaire) => {
    let sort = 0
    let page = 0
    let part = null
    const length = questionnaire.questions.length
    const partMapClone = JSON.parse(JSON.stringify(questionnaire.partMap))
    
    const questionMap = {}
    for (let index = 0; index < length; index++) {
      const question = questionnaire.questions[index]
      // 1、九大组件逻辑校验处理
      if (question.hasOwnProperty('sort')) {
        question.sort = sort++
        question.page = page
        question.part = part
        questionMap[question.questionId] = question.sort
        // 1.1、检查跳题逻辑是否合法
        if (question.jumpLogic) {
          /^{/.test(question.jumpLogic) 
            ? condJumpLogicCheck(question, questionMap) 
            : noCondJumpLogicCheck(question, questionMap)
        }
        // 1.2、检查题目显示逻辑是否合法
        if (question.showLogic) {
          const isOr = question.showLogic.indexOf('|') > -1
          const isAnd = question.showLogic.indexOf('&') > -1
          if (isOr) {
            showLogicCheck(question, questionMap, '|')
          } else if (isAnd) {
            showLogicCheck(question, questionMap, '&')
          } else {
            showLogicCheck(question, questionMap, ' ')
          }
        }
        // 1.3、检查选项显示逻辑是否合法
        if (question.choices) {
          for (const choice of question.choices) {
            // 检查选项显示逻辑是否合法
            if (choice.showLogic) {
              const isOr = choice.showLogic.indexOf('|') > -1
              const isAnd = choice.showLogic.indexOf('&') > -1
              if (isOr) {
                showLogicCheck(choice, questionMap, '|')
              } else if (isAnd) {
                showLogicCheck(choice, questionMap, '&')
              } else {
                showLogicCheck(choice, questionMap, ' ')
              }
            }
          }
        }
        // 1.4、检查选项引用逻辑、行标题引用逻辑是否合法
        if (question.ref) {
          refLogicCheck(question, questionMap)
        }
      } 
      // 2、分页组件逻辑校验处理
      else if (question.questionType === 'PAGE') {
        // 重构页数顺序
        if (question.page !== 0) {
          page++
          question.page = page
          questionnaire.pageMap[page] = `第${page + 1}页`
        }
      } 
      // 3、章节组件逻辑校验处理
      else if (question.questionType === 'PART') {
        // 重构PART
        if (part == null) {
          part = -1
        }
        part++
        if (part !== question.part) {
          const oldPart = question.part
          question.part = part
          questionnaire.partMap[part] = partMapClone[oldPart]
        }
        questionnaire.partMap[part].page = page
        question.page = page
      }
    }
    // 重新更新逻辑设置的提示信息
    insertLogicTips(questionnaire)
  }
  return {
    checkMove
  }
}
export default checkMoveEffect