<!--
 * @Description: 题库题目
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-07 15:13:19
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 12:05:37
-->
<template>
  <div class="question-wrap">
    <div class="question-top">
      <div class="left">{{question.title}}&nbsp;&nbsp;<span>({{questionType2Text[question.questionType]}})</span></div>
      <div class="right">
        <div
          v-if="question.choices && question.choices.length > 0" 
          :class="{'choice-library': true, 
                   'choice-library-active': checkedList.length > 0
          }"
        >
          <span class="choice-library-icon iconfont">&#xe624;</span>
          <span class="choice-library-text" @click="handleTitleClick">选项库</span>
        </div>
        <a-checkbox
          @change="onQuestionCheckChange"
        />
      </div>
    </div>
    <div class="question-bottom" v-show="choicesVisible">
      <div class="choice-desc">选项描述</div>
      <div class="choices-wrap">
        <a-checkbox-group v-model:value="checkedList">
          <a-checkbox 
            v-for="(choice, index) in question.choices"
            :value="choice.choiceId"
          >{{choice.text}}</a-checkbox>
        </a-checkbox-group>
      </div>
      <div class="choice-all">
        <a-checkbox 
          v-model:checked="checkAll"
          :indeterminate="indeterminate"
          @change="onCheckAllChange"
        >全选</a-checkbox>
      </div>
    </div>
  </div>
</template>

<script>

import globalQuestionnaire from '@/class/useQuestionnaire'


import { computed, reactive, ref, toRefs, watch } from 'vue'
import { createUniqueId } from '@/utils/random'
import { deepCopy } from '@/utils/deepCopy'
import { questionType2Component, questionType2Text } from '@/utils/enum.js'

export default {
  name: 'Question',
  props: {
    item: Object
  },
  setup (props, ctx) {
    const questionLibrary = globalQuestionnaire.getQuestionLibrary()
    const choicesVisible = ref(false) // 是否显示选项内容
    const plainOptions = [] // 所有选项的choiceId
    const question = computed({
      get: () => {
        const a = questionType2Text[props.item.questionType]
        props.item.questionId = createUniqueId(10)
        props.item.description = ''
        props.item.componentName = questionType2Component[props.item.questionType]
        props.item.columnSplit = 1
        props.item.choices && props.item.choices.forEach(choice => {
          choice.choiceId = createUniqueId(8)
          choice.description = ""
          plainOptions.push(choice.choiceId)
        })
        return props.item
      }
    })
    const state = reactive({
      indeterminate: false,
      checkAll: false, // 是否全选
      checkedList: [], //已选中的选项id
    })
    // 全选事件
    const onCheckAllChange = e => {
      Object.assign(state, {
        checkedList: e.target.checked ? plainOptions : [],
        indeterminate: false,
      })
      const curQuestionId = question.value.questionId
      const targetQuestInChecked = questionLibrary.value.find(question => question.questionId === curQuestionId)
      if (e.target.checked && targetQuestInChecked) { // 全选时，如果题目已经勾选了，那么就已选题目列表questionLibrary 里面对应本题的选项也需要更新
        targetQuestInChecked.choices = question.value.choices
      }
    }
    const handleTitleClick = () => {
      if (!question.value.choices || question.value.choices.length === 0) return
      choicesVisible.value = !choicesVisible.value
    }
    // 获取题目
    const getCheckedQuestion = () => {
      const copyQuestion = deepCopy(question.value)
      copyQuestion.choices = []
      if (plainOptions.length > 0 && state.checkedList.length > 0) {
        copyQuestion.choices = question.value.choices.filter(choice => state.checkedList.find(checkedId => checkedId === choice.choiceId))
      }
      return copyQuestion
    }

    // 题目选择事件
    const onQuestionCheckChange = e => {
      if (e.target.checked) {
        questionLibrary.value.push(getCheckedQuestion())
      } else {
        const curQuestionId = question.value.questionId
        const indexOfLibrary = questionLibrary.value.findIndex(question => question.questionId === curQuestionId)
        questionLibrary.value.splice(indexOfLibrary, 1)
      }
    }
    // 选项选择监听
    watch(
      () => state.checkedList,
      val => {
        // 全选状态更新
        state.indeterminate = !!val.length && val.length < plainOptions.length
        state.checkAll = val.length === plainOptions.length
        // 已选题目选项
        const curQuestionId = question.value.questionId
        const indexOfLibrary = questionLibrary.value.findIndex(question => question.questionId === curQuestionId)
        if (indexOfLibrary >= 0) {
          const targetQuestion = questionLibrary.value[indexOfLibrary]
          targetQuestion.choices = question.value.choices.filter(choice => state.checkedList.indexOf(choice.choiceId) >= 0)
        }
      },
    )
    return {
      question,
      questionType2Text,
      plainOptions,
      choicesVisible,
      ...toRefs(state), 
      onCheckAllChange,
      onQuestionCheckChange,
      handleTitleClick
    }
  }
}
</script>

<style lang="less">
.question-wrap {
  color: #2D2D33;
  margin-bottom: 8px;
  .question-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    font-size: 16px;
    font-weight: lighter;
    color: #2D2D33;
    background-color: #f7f7f7;
    &:hover{
      background-color: #f0f0f0!important;
    }
    .left {
      flex: 0 0 70%;
      line-height: 22px;
      cursor: pointer;
    }
    .right {
      display: flex;
      align-items: center;
      .choice-library {
        margin-right: 60px;
        cursor: pointer;
        &:hover {
          .choice-library-icon {
            cursor: pointer;
            color: #00A0E9
          }
          .choice-library-text {
            cursor: pointer;
            color: #00A0E9;
            text-decoration-color: #00A0E9;
          }
        }
        .choice-library-icon {
          color: #2D2D334A;
        }
        .choice-library-text {
          text-decoration: underline;
          text-decoration-color: #2d2d334a;
        }
        .active {
          color: #00A0E9
        }
      }
      .choice-library-active {
        .choice-library-icon {
          cursor: pointer;
          color: #00A0E9
        }
        .choice-library-text {
          cursor: pointer;
          color: #00A0E9;
          text-decoration-color: #00A0E9;
        }
      }
      .ant-checkbox-wrapper {
        font-weight: lighter;
        .ant-checkbox {
          // .ant-checkbox-inner {
          //   width: 24px;
          //   height: 24px;
          //   &::after {
          //     width: 8px;
          //     height: 16px;
          //   }
          // }
        }
        span {
          font-weight: lighter;
        }
      }
      
    }
  }
  .question-bottom {
    margin-top: 6px;
    border: 1px solid #E1E1E8;
    padding: 12px 18px;
    .choice-desc {
      font-size: 16px;
      font-weight: lighter;
      padding-bottom: 12px;
      border-bottom: 1px solid #E1E1E8;
    }
    .choices-wrap {
      .ant-checkbox-group {
        padding: 12px 0;
        display: flex;
        flex-wrap: wrap;
        .ant-checkbox-wrapper {
          font-weight: lighter;
          margin-left: 0;
          margin-bottom: 8px;
          flex: 0 0 33.33%;
          display: flex;
          .ant-checkbox {
            top: 3px;
          }
          .ant-checkbox-checked {
            height: auto;
            &::after {
              height: auto;
            }
          }
        }
      }
    }
    .choice-all {
      .ant-checkbox-wrapper>span {
        color: #00A0E9;
        text-decoration: underline;
        text-decoration-color: #00A0E9;
      }
    }
  }
}
</style>